<template>
  <section-description-photo
    :title="translations.homeDescriptionTitle"
    :description="documentToHtmlString(translations.homeDescriptionDescription)"
    :link-name="translations.homeDescriptionDescriptionLinkName"
    :link="true"
    :image="getImgUrl(translations.homeDescriptionPhoto)"
    :padding="'0px'"
    @navigate="goToAbout"
  />
</template>

<script>
  import SectionDescriptionPhoto from '@/views/sections/reusable/DescriptionPhoto'
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_HOME } from '@/views/home/Index.vue'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  import { PL, EN } from '@/constants/lang.js'
  import getImgUrl from '@/utils/getImgUrl.js'

  export default {
    name: 'SectionHomeDescription',
    components: {
      SectionDescriptionPhoto,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_HOME, [
        'translations',
      ]),
    },
    data: () => ({
      image: 'chemorozruch.jpg',
    }),
    methods: {
      goToAbout () {
        const name = this.$route.meta.lang === PL ? 'About-pl' : 'About-en'
        this.$router.push({ name })
      },
      documentToHtmlString,
      getImgUrl,
    },
  }
</script>

<template>
  <base-section
    id="pro-features"
    :style="{'padding': padding}"
  >
    <v-container>
      <v-row
        align="center"
        justify="space-between"
      >
        <v-col
          cols="12"
          md="7"
          :class="{'order-1': left}"
        >
          <base-info-card
            :title="title"
            pace="6"
          >
            <div
              class="text-center"
              v-html="description"
            />
          </base-info-card>
          <div class="text-center">
            <base-btn
              v-if="link"
              :tile="false"
              color="secondary"
              @click="navigate"
            >
              {{ linkName }}
            </base-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          :class="{'order-0': left}"
        >
          <v-card
            elevation="16"
            class="mb-12"
          >
            <base-img
              max-width="100%"
              :src="image"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionDescriptionPhoto',
    props: {
      title: {
        type: String,
      },
      description: {
        type: String,
        default: 'description',
      },
      link: {
        type: Boolean,
        default: false,
      },
      linkName: {
        type: String,
      },
      image: {
        type: String,
      },
      left: {
        type: Boolean,
      },
      padding: {
        type: String,
        default: 'padding: 96px 0px',
      },
    },
    methods: {
      navigate () {
        this.$emit('navigate')
      },
    },
  }
</script>
